<template>
	<var-skeleton :rows="7" :loading="loadingData">
		<var-row>
			<breadcrumbs current-item-title="Update Command" :items="breadcrumbItems" />
		</var-row>
		<var-card
			title="Update Command"
			class="mb-4"
		>
			<template #extra>
				<var-form ref="form" :disabled="loading">
					<var-input
						placeholder="Description"
						:rules="[v => !!v || 'Command description cannot be empty']"
						v-model="formData.description"
						class="mb-3"
					/>
					<var-input
						placeholder="Body"
						:rules="[v => !!v || 'Command body cannot be empty']"
						textarea
						v-model="formData.text"
						class="mb-3"
					/>
					<var-row :gutter="10">
						<var-col :span="12">
							<var-select
								placeholder="Duration"
								:rules="[v => !!v || 'You must select a duration']"
								v-model="formData.duration_id"
								class="mb-3"
							>
								<var-option v-for="duration in durations" :label="duration.text" :value="duration.id"  />
							</var-select>
						</var-col>
						<var-col :span="12">
							<var-select
								placeholder="Execution state"
								:rules="[v => !!v || 'You must select an execution state']"
								v-model="formData.execution_state"
								class="mb-3"
							>
								<var-option v-for="(executionState, key) in executionStates" :label="executionState" :value="key"  />
							</var-select>
						</var-col>
					</var-row>

					<var-row :gutter="10">
						<var-col :span="12">
							<var-select
								placeholder="Frequency"
								:rules="[v => !!v || 'You must select a frequency']"
								v-model="formData.frequency"
								class="mb-3"
							>
								<var-option v-for="(frequency, key) in frequencies" :label="frequency" :value="key"  />
							</var-select>
						</var-col>
						<var-col :span="12">
							<var-select
								placeholder="Servers"
								multiple
								:rules="[v => v.length >= 1 || 'You must select at least one server']"
								v-model="formData.server_ids"
								class="mb-3"
							>
								<var-option v-for="server in servers" :label="server.name" :value="server.id"  />
							</var-select>
						</var-col>
					</var-row>

					<var-button type="primary" @click="updateCommand" class="mt-3" :loading="loading">Save</var-button>
				</var-form>
			</template>
		</var-card>
	</var-skeleton>
</template>

<script>
import {reactive, ref} from "vue";
import request from "@/request";
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
	name: "UpdateCommand",
	components: {Breadcrumbs},
	props: {

	},
	created() {
		this.getCommand()
	},
	setup() {
		const formData = reactive({
			duration_id: null,
			description: null,
			text: null,
			execution_state: null,
			frequency: null,
			server_ids: []
		})

		const form = ref(null)

		return {
			form,
			formData
		}
	},
	data() {
		return {
			loading: false,
			loadingData: false,
			breadcrumbItems: [
				{
					title: 'Projects',
					to: 'Projects',
					params: {}
				}
			],
			durations: null,
			executionStates: null,
			frequencies: null,
			servers: null
		}
	},
	methods: {
		getCommand() {
			this.loadingData = true;
			request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.$route.params.project_id + '/commands/' + this.$route.params.command_id)
				.then(data => {
					this.loadingData = false

					this.breadcrumbItems.push({
						title: data.project,
						to: 'ProjectDetails',
						params: {
							id: this.$route.params.project_id
						}
					})

					this.servers = data.servers
					this.durations = data.durations
					this.executionStates = data.execution_states
					this.frequencies = data.frequencies

					this.collectData(data.command)
				})
				.catch(error => {
					this.loadingData = false
					console.log(error)
				})
		},
		collectData(data) {
			this.formData.duration_id = data.duration_id
			this.formData.description = data.description
			this.formData.text = data.text
			this.formData.execution_state = data.execution_state
			this.formData.frequency = data.frequency
			this.formData.server_ids = data.server_ids
		},
		updateCommand() {
			this.loading = true
			request.put(process.env.VUE_APP_BASE_URL + '/projects/' + this.$route.params.project_id + '/commands/' + this.$route.params.command_id, this.formData)
				.then(data => {
					console.log(data)
					this.loading = false
					this.$router.push({
						name: 'ProjectDetails',
						params: {
							id: this.$route.params.project_id,
							tab: 'ssh_commands'
						}
					})
				})
				.catch(error => {
					console.log(error)
					this.loading = false
				})
		}
	}
}
</script>

<style scoped>

</style>